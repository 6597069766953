<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner sub-page pt-6 md:pt-12">
      <div class="mb-14 sm:mb-20">
        <h1 class="text-royalblue-900 mb-10">
          Balance Neuro Physiotherapy Privacy Policy
        </h1>
        <div class="flex flex-wrap -m-4">
          <div class="w-full p-4">
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              This privacy notice explains why we collect information about you
              and how this information may be used.
            </h2>
            <p>
              In this Privacy Policy, references to “we”, “us”, “practice” or
              “our” are to any physiotherapists that are part of the Balance
              Neuro Physiotherapy Co-operative.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              How we use your personal information:
            </h2>
            <p>
              We take your privacy very seriously and will only use your
              personal information to administer your account and to provide the
              services you have requested from us. Our professionals maintain
              records about your health, treatment plan and any treatment or
              care you have received previously that is relevant to your
              rehabilitation.These records help us to provide you with the best
              possible care and to facilitate treatment specifically tailored to
              your individual needs. The records we hold may be electronic, on
              paper or a mixture of both, and we use a combination of working
              practices and technology to ensure that your information is kept
              confidential and secure.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Records which we hold about you may include the following
              information:
            </h2>
            <ul>
              <li>
                Details about you, i.e. your date of birth, address, telephone
                number/s, email, diagnosis, emergency contact, etc.
              </li>
              <li>
                Any contact we have had with you, i.e. appointments, clinic
                visits, hydrotherapy, home visits, classes etc.
              </li>
              <li>
                Details about your treatment and care – past and present e.g.
                notes, letters and reports about your health.
              </li>
              <li>
                Results from other organisations e.g. orthotics, technical
                equipment, mobility aids etc.
              </li>
              <li>
                Relevant information from other health professionals, legal
                representatives, case managers, relatives or those who care for
                you.
              </li>
              <li>
                Information to enable us to send, receive and process invoices
                for services you have received such as via third-party payers
                e.g. health insurance companies.
              </li>
            </ul>
            <p>
              Sometimes your information may be requested by us to be used for
              our own marketing purposes – we will always gain your consent
              before releasing any information gathered for this purpose. We
              will only ever use or pass on information about you if others
              involved in your treatment have a genuine need for it and we have
              asked your consent for us to do this. See consent form attached.
              We will not disclose your information to any third party without
              your permission unless there are exceptional circumstances i.e.
              life or death situations or where the law requires information to
              be passed on.
            </p>
            <p>
              We comply with obligations under General Data Protection
              Regulations (GDPR) by keeping personal data up to date; by storing
              and destroying it securely; by not collecting or retaining
              excessive amounts of data; by protecting personal data from loss,
              misuse, unauthorised access and disclosure and by ensuring that
              appropriate technical measures are in place to protect personal
              data. You will be informed if your data needs to be shared and, in
              most cases, you will be asked for explicit consent for this to
              happen. See attached consent form. Any external companies that we
              may share your data with are bound by contractual agreements to
              ensure your information is kept confidential and secure.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Access to personal information:
            </h2>
            <p>
              You have a right under the Data Protection Act 1998 / GDPR 2018 to
              request access to view or to obtain copies of what information we
              hold about you and to have it amended should it be inaccurate. In
              order to request this, you need to do the following:
            </p>
            <ul>
              <li>
                Your request must be made in writing to the physiotherapist that
                is treating you.
              </li>
              <li>
                There may be a charge to have a printed copy of the information
                held about you.
              </li>
              <li>We are required to respond to you within 20 working days.</li>
              <li>
                You will need to give adequate information (for example full
                name, address, date of birth, and details of your request) and
                your identity must be verified and your records located.
              </li>
              <li>
                We are legally obliged to hold your data for 8 years (for
                adults) and until children reach the age of 21.
              </li>
            </ul>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Change to your personal details:
            </h2>
            <p>
              It is important that you tell the person treating you if any of
              your details such as your name or address have changed or if any
              of your details such as date of birth is incorrect in order for
              this to be amended. You have a responsibility to inform us of any
              changes so our records are accurate and up to date for you.
              Notification GDPR requires organisations to register a
              notification with the Information Commissioners Office to describe
              the purposes for which they process personal and sensitive
              information. This information is publicly available on the
              Information Commissioners Office website www.ico.org.uk We are
              registered with the Information Commissioners Office (ICO). The
              Data Controller, responsible for keeping your information secure
              and confidential is: Diane Ormsby/ Gemma Brown.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              We shall request written consent regarding our holding, provision
              and sharing of your personal data.
            </h2>
            <p>
              Objections /Complaints Should you have any concerns about how your
              information is managed by us please contact your treating
              physiotherapist.
            </p>
            <p>
              If you are still unhappy following a review by us you can then
              complain to the Information Commissioners Office (ICO).
              www.ico.org.uk casework@ico.org.uk
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              If for any reason you are disatisfied with our service:
            </h2>
            <p class="font-semibold">
              Raising Your Concerns or Making a Complaint:
            </p>
            <ul>
              <li>
                Balance Neuro Physiotherapy aims to provide a high quality
                physiotherapy service.
              </li>
              <li>
                We understand that constructive feedback and comments can help
                us tofurther improve. We will welcome your comments to help us
                achieve this. There may, however, be occasions when we fail to
                meet your expectations and you feel that a complaint is
                justified.
              </li>
            </ul>
            <p class="font-semibold">Our aim is:</p>
            <ul>
              <li>To resolve issues quickly</li>
              <li>To be prompt, open and thorough</li>
              <li>To be supportive and resolve problems without blame</li>
              <li>To improve our service</li>
              <li>
                To provide information if the problem cannot be resolved via
                discussion with the practice.
              </li>
            </ul>
            <p class="font-semibold">
              What to do if you have a concern or complaint:
            </p>
            <ul>
              <li>
                Please raise your concerns immediately, either with the
                therapist you are in contact with, or contact Diane Ormsby
                either verbally or in writing.
              </li>
              <li>
                In many cases it will be possible to resolve the issue
                immediately. It is wise to raise your concern promptly
                (immediately or within days).
              </li>
              <li>
                We shall keep documented evidence of your raised concern in a
                Complaints File kept securely and confidentially.
              </li>
            </ul>
            <p class="font-semibold">
              If your concerns have not been adequately addressed, please
              contact either:
            </p>
            <ul>
              <li>The Chartered Society of Physiotherapy</li>
              <li>The Health and Care Professions Council</li>
              <li>or the Health Service Ombudsman.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Privacy Policy",
};
</script>
